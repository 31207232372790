/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css'

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
        `We have some new features for Cocktail Pantry Search ` +
        `Reload to display the latest version?`
    )
    if (answer === true) {
        window.location.reload()
    }
}